<template>
  <b-container
    class="mb-5"
    fluid
  >

    <div class="d-flex justify-content-end mb-3">
      <b-breadcrumb
        v-if="$route.meta.breadcrumb"
        :items="$route.meta.breadcrumb || []"
      />
    </div>

    <b-card>
      <b-row class="mb-2 px-2">

        <b-col
          cols="12"
          md="9"
          class="px-1"
        >
          <b-form-group>
            <label
              for="filter_search"
            >
              <strong>
                Search
              </strong>
            </label>
            <b-input
              id="filter_search"
              v-model="tableItems.filter"
              type="text"
              placeholder="search here"
              debounce="1000"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          md="3"
          class="px-1"
        >
          <b-form-group>
            <label
              for="filter_category"
            >
              <strong>
                Category
              </strong>
            </label>
            <b-select
              id="filter_category"
              v-model="filter.category"
              :options="filterCategories"
              :disabled="state.busy || fetching.filter.categories"
              @change="onRefreshTableItems"
            />
          </b-form-group>
        </b-col>

      </b-row>

      <b-table
        ref="tableItems"
        hover
        responsive
        show-empty
        class="mt-2"
        :per-page="tableItems.perPage"
        :current-page="tableItems.currentPage"
        :items="tableItemsProvider"
        :fields="tableItems.fields"
        :sort-by.sync="tableItems.sortBy"
        :sort-desc.sync="tableItems.sortDesc"
        :sort-direction="tableItems.sortDirection"
        :filter="tableItems.filter"
        :filter-included-fields="tableItems.filterOn"
      >
        <template #cell(index)="row">
          {{ tableItems.currentPage * tableItems.perPage - tableItems.perPage + (row.index + 1) }}
        </template>

        <template #cell(action)="row">
          <div class="text-nowrap">
            <b-button
              size="sm"
              class="mr-1"
              @click="onViewItem(row.item)"
            >
              View
            </b-button>
          </div>
        </template>

        <template #cell(item_code)="row">
          <div class="text-nowrap">
            <strong>{{ row.value }}</strong>
          </div>
        </template>

        <template #cell(serial_number)="row">
          <div class="text-nowrap">
            <strong>{{ row.value }}</strong>
          </div>
        </template>

        <template #cell(employee_name)="row">
          <div class="text-nowrap">
            <strong>{{ row.value }}</strong>
          </div>
        </template>

        <template #cell()="row">
          <div class="text-nowrap">
            {{ row.value }}
          </div>
        </template>

      </b-table>

      <b-row>
        <b-col
          cols="12"
          sm="6"
        >
          <div class="w-100 w-sm-25 mb-2 sm-mb-2">
            <b-select
              v-model="tableItems.perPage"
              :options="tableItems.pageOptions"
              size="sm"
            />
          </div>
        </b-col>
        <b-col
          cols="12"
          sm="6"
          class="d-flex justify-content-center justify-content-sm-end"
        >
          <b-pagination
            v-model="tableItems.currentPage"
            :total-rows="tableItems.totalRows"
            :per-page="tableItems.perPage"
            first-number
            last-number
            pills
            prev-text="Prev"
            next-text="Next"
            aria-controls="table"
          />
        </b-col>
      </b-row>
    </b-card>

    <b-modal
      id="modal-item"
      size="xl"
      scrollable
      no-close-on-backdrop
      title="Item"
      aria-hidden="false"
    >
      <b-row>
        <b-col
          cols="12"
        >
          <b-row>

            <b-col
              cols="12"
              md="4"
            >
              <b-form-group>
                <label
                  for="item_code"
                >
                  <strong>
                    Item Code
                  </strong>
                </label>
                <b-input
                  id="item_code"
                  v-model="item.item_code"
                  type="text"
                  autocomplete="off"
                  disabled
                />
              </b-form-group>
            </b-col>

            <b-col
              cols="12"
              md="4"
            >
              <b-form-group>
                <label
                  for="type_name"
                >
                  <strong>
                    Type
                  </strong>
                </label>
                <b-input
                  id="type_name"
                  v-model="item.type_name"
                  type="text"
                  autocomplete="off"
                  disabled
                />
              </b-form-group>
            </b-col>

            <b-col
              cols="12"
              md="4"
            >
              <b-form-group>
                <label
                  for="category_name"
                >
                  <strong>
                    Category
                  </strong>
                </label>
                <b-input
                  id="category_name"
                  v-model="item.category_name"
                  type="text"
                  autocomplete="off"
                  disabled
                />
              </b-form-group>
            </b-col>

          </b-row>
        </b-col>

        <b-col
          cols="12"
          md="4"
        >
          <b-form-group>
            <label
              for="serial_number"
            >
              <strong>
                Serial Number
              </strong>
            </label>
            <b-input
              id="serial_number"
              v-model="item.serial_number"
              type="text"
              placeholder="enter serial number"
              autocomplete="off"
              disabled
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          md="8"
        >
          <b-form-group>
            <label
              for="item_name"
            >
              <strong>
                Name
              </strong>
            </label>
            <b-input
              id="item_name"
              v-model="item.item_name"
              type="text"
              placeholder="enter name"
              autocomplete="off"
              disabled
            />
          </b-form-group>
        </b-col>

        <b-col cols="12 d-none d-md-block">
          <hr>
        </b-col>

        <b-col
          cols="12"
          md="6"
        >
          <b-form-group>
            <label for="supplier_name">
              <strong>
                Supplier
              </strong>
            </label>
            <b-input
              id="supplier_name"
              v-model="item.supplier_name"
              type="text"
              placeholder="enter supplier name"
              autocomplete="off"
              disabled
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          md="6"
        >
          <b-form-group>
            <label for="brand_name">
              <strong>
                Brand
              </strong>
            </label>
            <b-input
              id="brand_name"
              v-model="item.brand_name"
              type="text"
              placeholder="enter brand name"
              autocomplete="off"
              disabled
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          md="6"
        >
          <b-form-group>
            <label for="purchase_date">
              <strong>
                Purchase Date
              </strong>
            </label>
            <b-input
              id="purchase_date"
              v-model="item.purchase_date"
              type="text"
              placeholder="enter purchase date"
              autocomplete="off"
              disabled
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          md="6"
        >
          <b-form-group>
            <label for="original_cost">
              <strong>
                Cost
              </strong>
            </label>
            <b-input
              id="original_cost"
              v-model="item.original_cost"
              type="number"
              placeholder="enter cost"
              autocomplete="off"
              step="0.01"
              disabled
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
        >
          <b-form-group>
            <label for="specifications">
              <strong>
                Specifications
              </strong>
            </label>
            <b-textarea
              id="specifications"
              v-model="item.specifications"
              rows="6"
              max-rows="12"
              placeholder="enter specifications"
              disabled
            />
          </b-form-group>
        </b-col>
      </b-row>

      <template #modal-footer="{ cancel }">
        <b-button
          variant="danger"
          :disabled="state.busy"
          @click="cancel()"
        >
          Close Window
        </b-button>
      </template>
    </b-modal>

  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { UserItemService, SharedListService } from '@/services'
import formatter from '@/mixins/formatter'

export default {
  name: 'UserItems',

  middleware: ['auth', 'user'],

  metaInfo () {
    return {
      title: 'Items'
    }
  },

  mixins: [formatter],

  data () {
    return {
      state: {
        busy: false,
        editing: false
      },
      fetching: {
        filter: {
          categories: false
        }
      },
      filter: {
        category: 'All'
      },
      list: {
        filter: {
          categories: []
        }
      },
      selected: {
        brand: null,
        supplier: null
      },
      item: {
        id: null,
        template: null,
        template_name: null,
        type_name: null,
        category_name: null,
        supplier_name: null,
        brand_name: null,
        item_code: null,
        item_name: null,
        serial_number: null,
        purchase_date: null,
        original_cost: 0.00,
        specifications: null,
        active: 0
      },
      tableItems: {
        perPage: 10,
        pageOptions: [10, 25, 50, 100],
        totalRows: 0,
        currentPage: 1,
        sortBy: null,
        sortDesc: false,
        sortDirection: 'asc',
        filter: null,
        filterOn: [],
        fields: [
          { key: 'index', class: 'text-center' },
          { key: 'action', class: 'text-center' },
          { key: 'item_code' },
          { key: 'category_name', label: 'category' },
          { key: 'serial_number' },
          { key: 'item_name' },
          { key: 'status_name', label: 'status' },
          { key: 'updated_at', formatter: this.dateTimeShortFormatter }
        ]
      }
    }
  },

  computed: {
    filterCategories () {
      return [{ text: 'All', value: 'All' }].concat(this.list.filter.categories)
    }
  },

  mounted () {
    core.index()
    this.getFilterCategories()
  },

  methods: {
    async tableItemsProvider (ctx) {
      return await UserItemService.get(
        this.objectToUrl({
          page: ctx.currentPage,
          per_page: ctx.perPage,
          sort: ctx.sortBy,
          sort_desc: ctx.sortDesc,
          filter_text: ctx.filter,
          filter_category: this.filter.category
        })
      ).then(({ data }) => {
        this.tableItems.totalRows = data.total_rows
        return data.items
      }).catch(() => {
        return []
      })
    },

    async getFilterCategories () {
      this.fetching.filter.categories = this.state.busy = true
      await SharedListService.getCategories('parent=1').then(({ data }) => {
        this.list.filter.categories = data.map(({ id, category_name }) => ({ value: id, text: category_name }))
        this.fetching.filter.categories = this.state.busy = false
      })
    },

    onRefreshTableItems () {
      this.$refs.tableItems.refresh()
    },

    onViewItem (item) {
      this.state.editing = true

      this.item.id = item.id
      this.item.item_code = item.item_code
      this.item.item_name = item.item_name
      this.item.serial_number = item.serial_number
      this.item.purchase_date = this.dateLongFormatter(item.purchase_date)
      this.item.original_cost = item.original_cost
      this.item.specifications = item.specifications
      this.item.active = item.active

      this.item.type_name = item.type_name
      this.item.category_name = item.category_name
      this.item.supplier_name = item.supplier_name
      this.item.brand_name = item.brand_name

      this.$bvModal.show('modal-item')
    }
  }
}
</script>
